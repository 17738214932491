import * as React from 'react';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



export default function CitationCard() {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
        Additional Resources:
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          1. Haoyu Liu, Paul Patras, and Douglas J. Leith. "<Link href="https://doi.org/10.1371/journal.pone.0279942">On the data privacy practices of Android OEMs.</Link>", <em>PloS one 18.1 (2023): e0279942</em>.
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          2. Haoyu Liu, Douglas J. Leith, and Paul Patras. "<Link href="https://arxiv.org/abs/2302.01890">Android OS Privacy Under the Loupe -- A Tale from the East</Link>", to appear in <em>Proceedings of the 16th ACM Conference on Security and Privacy in Wireless and Mobile Networks</em>, 2023.
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          3. Haoyu Liu, Douglas J. Leith, and Paul Patras. "<Link href="https://github.com/Mobile-Intelligence-Lab/android_CN_trafficdata/blob/main/Wisec23_additional_material.pdf">Additional Material for Android OS Privacy Under the Loupe</Link>", Technical Report, <em>Mobile Intelligence Lab</em>.
        </Typography>
      </CardContent>
    </Card>
  );
}