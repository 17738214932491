// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import CitationCard from 'src/components/_dashboard/app/AppCitationCard';

// ----------------------------------------------------------------------

export default function Citation() {
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={12}>
            <CitationCard />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}