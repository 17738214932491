// material
import { Card, CardHeader, Box } from '@mui/material';
//
import CNMapView from './CNMapView';

// ----------------------------------------------------------------------


export default function AppCNMapConn() {

  return (
    <Card>
      <CardHeader title="Chinese Firmware Connections Map" />
        <CNMapView />
    </Card>
    
  );
}