// material
import { Box, Grid, Container, Typography, Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function DashboardSummary() {
    return (
        <Page title="Dashboard Summary">
            <Container maxWidth="xl">
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            A Detailed Privacy Analysis of the Android OS Firmware
                        </Typography>
                        <br />
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        We conducted cross-regional analysis to study the data transmitted by the preinstalled system apps on Android smartphones from the most popular vendors in both China and EU, including, <em>Xiaomi, Samsung, Huawei, Oppo and Oneplus</em>. 
                        We found that an alarming number of preinstalled system, vendor and third-party apps transmit to backend servers privacy-sensitive information related to the <b>user's device</b> (persistent identifiers), <b>geolocation</b> (GPS coordinates, network-related identifiers), <b>user profile</b> (phone number, app usage) and <b>social relationships</b> (e.g., call history), without consent or even notification. 
                        <br />
                        <br />
                        Although the firmware is produced by the same companies (Xiaomi, Oppo and Oneplus), Chinese versions transmit much more sensitive information than the EU counterparts.
                        This poses serious deanonymization and tracking risks that extend outside China when the user leaves the country, and calls for a more rigorous enforcement of the recently adopted data privacy legislation.

                        <br />
                        <br />

                        The <b>Dashboard</b> in the left panel visualizes the geolocations where the data is sent to by different Android OS firmware versions. Each specific tab details the private information sent by each mobile device.   
                        Our findings are further detailed in research papers, which interested readers can refer to.
                        <br />
                        <br />

                        Haoyu Liu, Douglas J. Leith and Paul Patras
                        </Typography>
                    </CardContent>
                    {/* <CardActions>
                        <Button size="small"><Link href='/citation'>Learn More</Link></Button>
                    </CardActions> */}
                </Card>
            </Container>
        </Page>
    );
}