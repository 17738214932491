import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import RealmeView from './pages/Realme';
import DashboardApp from './pages/DashboardApp';
import HuaweiView from './pages/Huawei';
import SamsungView from './pages/Samsung';
import XiaomiView from './pages/Xiaomi';
import CNRealmeView from './pages/Realme_CN';
import CNOneplusView from './pages/Oneplus_CN';
import CNXiaomiView from './pages/Xiaomi_CN';
import Citation from './pages/Citation';
import DashboardSummary from './pages/DashboardSummary';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/summary" />, index: true},
        { path: 'summary', element: <DashboardSummary />},
        { path: 'app', element: <DashboardApp /> },
        { path: 'xiaomi', element: <XiaomiView /> },
        { path: 'huawei', element: <HuaweiView /> },
        { path: 'samsung', element: <SamsungView /> },
        { path: 'realme', element: <RealmeView />},
        { path: 'xiaomi-cn', element: <CNXiaomiView /> },
        { path: 'oneplus-cn', element: <CNOneplusView /> },
        { path: 'realme-cn', element: <CNRealmeView />},
        { path: 'citation', element: <Citation />},
      ]
    },
    // { path: '*', element: <Navigate to="/404.html" replace /> }
  ]);
}
