import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import { CardMedia } from '@mui/material';
import xiaomiPNG from './xiaomi_icon.png';
import huaweiPNG from './huawei_icon.png';
import samsungPNG from './samsung_icon.png';
import realmePNG from './realme-mobile-logo-icon.png';
import oneplusPNG from './one-plus.png';
import paperPNG from './document.png';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Xiaomi (Global)',
    path: '/xiaomi',
    icon: <CardMedia
          style={{height: 22}}
          src={xiaomiPNG}
          component="img"
          />
  },
  {
    title: 'Huawei (Global)',
    path: '/huawei',
    icon: <CardMedia
          style={{height: 22}}
          src={huaweiPNG}
          component="img"
          />
  },
  {
    title: 'Samsung (Global)',
    path: '/samsung',
    icon: <CardMedia
          style={{height: 22}}
          src={samsungPNG}
          component="img"
          />
  },
  {
    title: 'Realme (Global)',
    path: '/realme',
    icon: <CardMedia
          style={{height: 22}}
          src={realmePNG}
          component="img"
          />
  },
  {
    title: 'Xiaomi (CN)',
    path: '/xiaomi-cn',
    icon: <CardMedia
          style={{height: 22}}
          src={xiaomiPNG}
          component="img"
          />
  },
  {
    title: 'Oneplus (CN)',
    path: '/oneplus-cn',
    icon: <CardMedia
          style={{height: 22}}
          src={oneplusPNG}
          component="img"
          />
  },
  {
    title: 'Realme (CN)',
    path: '/realme-cn',
    icon: <CardMedia
          style={{height: 22}}
          src={realmePNG}
          component="img"
          />
  },
  {
    title: 'Research Papers',
    path: '/citation',
    icon: <CardMedia
          style={{height: 22}}
          src={paperPNG}
          component="img"
          />
  }
];

export default sidebarConfig;
